import type { RouteRecordRaw } from 'vue-router';
import { authEnumRoutes } from './auth.enum.routes';

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: authEnumRoutes.root.path,
    name: authEnumRoutes.root.name,
    redirect: authEnumRoutes.login.path,
    component: () => import('@/layouts/auth/BaseAuthLayout.vue'),
    children: [
      {
        path: authEnumRoutes.login.path,
        name: authEnumRoutes.login.name,
        component: () => import('@/modules/auth/pages/LoginPage.vue'),
        meta: authEnumRoutes.login.meta,
      },
      {
        path: authEnumRoutes.forgotPassword.path,
        name: authEnumRoutes.forgotPassword.name,
        component: () => import('@/modules/auth/pages/ForgetPassword.vue'),
        meta: authEnumRoutes.forgotPassword.meta,
      },
      {
        path: authEnumRoutes.forgetPassword.path,
        redirect: authEnumRoutes.forgotPassword.path,
      },
      {
        path: authEnumRoutes.resetPassword.path,
        name: authEnumRoutes.resetPassword.name,
        component: () => import('@/modules/auth/pages/ResetPassword.vue'),
        meta: authEnumRoutes.resetPassword.meta,
      },
      {
        path: authEnumRoutes.twoFactorVerification.path,
        name: authEnumRoutes.twoFactorVerification.name,
        component: () =>
          import('@/modules/auth/pages/TwoFactorVerification.vue'),
        meta: authEnumRoutes.twoFactorVerification.meta,
      },
    ],
  },
];
export default authRoutes;
