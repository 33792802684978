import type { RouteRecordRaw } from 'vue-router';
import { adminEnumRoutes } from './admin.enum.routes';

const adminCoursesRoutes: Array<RouteRecordRaw> = [
  {
    path: adminEnumRoutes.courses.path,
    name: adminEnumRoutes.courses.name,
    meta: adminEnumRoutes.courses.meta,
    component: () =>
      import('@/modules/admin/pages/courses/CourseBaseLayout.vue'),
    children: [
      {
        path: adminEnumRoutes.myCourses.path,
        name: adminEnumRoutes.myCourses.name,
        meta: adminEnumRoutes.myCourses.meta,
        component: () =>
          import('@/modules/admin/pages/courses/MyCoursesPage.vue'),
      },
      {
        path: adminEnumRoutes.importedCourses.path,
        name: adminEnumRoutes.importedCourses.name,
        meta: adminEnumRoutes.importedCourses.meta,
        component: () =>
          import('@/modules/admin/pages/courses/ImportedCoursesPage.vue'),
      },
      {
        path: adminEnumRoutes.externalCourses.path,
        name: adminEnumRoutes.externalCourses.name,
        meta: adminEnumRoutes.externalCourses.meta,
        component: () =>
          import('@/modules/admin/pages/courses/ExternalCoursesPage.vue'),
      },
      {
        path: adminEnumRoutes.specialization.path,
        name: adminEnumRoutes.specialization.name,
        meta: adminEnumRoutes.specialization.meta,
        component: () =>
          import('@/modules/admin/pages/courses/SpecializationPage.vue'),
      },
    ],
  },

  {
    path: adminEnumRoutes.adminCourseOverview.path,
    name: adminEnumRoutes.adminCourseOverview.name,
    meta: adminEnumRoutes.adminCourseOverview.meta,
    redirect: adminEnumRoutes.adminCourseDetails.path,
    component: () =>
      import('@/modules/admin/components/my-courses/MyCourseOverview.vue'),
    children: [
      {
        path: adminEnumRoutes.adminCourseDetails.path,
        name: adminEnumRoutes.adminCourseDetails.name,
        component: () =>
          import('@/modules/admin/components/my-courses/CourseDetails.vue'),
      },
      {
        path: adminEnumRoutes.adminCourseInstructors.path,
        name: adminEnumRoutes.adminCourseInstructors.name,
        component: () =>
          import('@/modules/admin/components/my-courses/CourseInstructors.vue'),
      },
      {
        path: adminEnumRoutes.adminCourseStudents.path,
        name: adminEnumRoutes.adminCourseStudents.name,
        component: () =>
          import('@/modules/admin/components/my-courses/CourseStudents.vue'),
      },
      {
        path: adminEnumRoutes.adminCourseInterestedStudents.path,
        name: adminEnumRoutes.adminCourseInterestedStudents.name,
        component: () =>
          import(
            '@/modules/admin/components/my-courses/CourseInterestedStudents.vue'
          ),
      },
      {
        path: adminEnumRoutes.audienceControl.path,
        name: adminEnumRoutes.audienceControl.name,
        component: () =>
          import('@/modules/admin/components/CourseVisiblity.vue'),
      },
      {
        path: adminEnumRoutes.courseCertificate.path,
        name: adminEnumRoutes.courseCertificate.name,
        component: () =>
          import('@/modules/admin/components/CourseCertificate.vue'),
      },
      {
        path: adminEnumRoutes.courseReviews.path,
        name: adminEnumRoutes.courseReviews.name,
        component: () => import('@/modules/admin/components/CourseReviews.vue'),
      },
    ],
  },
  {
    path: adminEnumRoutes.importedCourseOverview.path,
    name: adminEnumRoutes.importedCourseOverview.name,
    meta: adminEnumRoutes.importedCourseOverview.meta,
    redirect: adminEnumRoutes.importedCourseDetails.path,
    component: () =>
      import(
        '@/modules/admin/components/imported-courses/ImportedCourseOverview.vue'
      ),
    children: [
      {
        path: adminEnumRoutes.importedCourseDetails.path,
        name: adminEnumRoutes.importedCourseDetails.name,
        component: () =>
          import(
            '@/modules/admin/components/imported-courses/ImportedCourseDetails.vue'
          ),
      },
      {
        path: adminEnumRoutes.importedCourseStudents.path,
        name: adminEnumRoutes.importedCourseStudents.name,
        component: () =>
          import(
            '@/modules/admin/components/imported-courses/ImportedCourseStudents.vue'
          ),
      },
      {
        path: adminEnumRoutes.importedCourseInstructors.path,
        name: adminEnumRoutes.importedCourseInstructors.name,
        component: () =>
          import(
            '@/modules/admin/components/imported-courses/ImportedCourseInstructors.vue'
          ),
      },
      {
        path: adminEnumRoutes.importedCourseAudienceControl.path,
        name: adminEnumRoutes.importedCourseAudienceControl.name,
        component: () =>
          import('@/modules/admin/components/CourseVisiblity.vue'),
      },
    ],
  },
  {
    path: adminEnumRoutes.externalCoursesOverview.path,
    name: adminEnumRoutes.externalCoursesOverview.name,
    meta: adminEnumRoutes.externalCoursesOverview.meta,
    component: () =>
      import(
        '@/modules/admin/components/external-courses/ExternalCourseOverview.vue'
      ),
    children: [
      {
        path: '',
        name: adminEnumRoutes.externalCoursesDetails.name,
        component: () =>
          import(
            '@/modules/admin/components/external-courses/ExternalCourseDetails.vue'
          ),
        meta: adminEnumRoutes.externalCoursesDetails.meta,
      },
      {
        path: adminEnumRoutes.externalCoursesStudents.path,
        name: adminEnumRoutes.externalCoursesStudents.name,
        component: () =>
          import(
            '@/modules/admin/components/external-courses/ExternalCourseEnrollee.vue'
          ),
        meta: adminEnumRoutes.externalCoursesStudents.meta,
      },
    ],
  },
  {
    path: adminEnumRoutes.specializationOverview.path,
    name: adminEnumRoutes.specializationOverview.name,
    meta: adminEnumRoutes.specializationOverview.meta,
    redirect: adminEnumRoutes.specializationDetails.path,
    component: () =>
      import(
        '@/modules/admin/components/specialization/SpecializationOverview.vue'
      ),
    children: [
      {
        path: adminEnumRoutes.specializationDetails.path,
        name: adminEnumRoutes.specializationDetails.name,
        component: () =>
          import(
            '@/modules/admin/components/specialization/SpecializationDetails.vue'
          ),
      },
      {
        path: adminEnumRoutes.specializationCreate.path,
        name: adminEnumRoutes.specializationCreate.name,
        component: () =>
          import(
            '@/modules/admin/components/specialization/SpecializationDetails.vue'
          ),
      },
      {
        path: adminEnumRoutes.SpecializationCourses.path,
        name: adminEnumRoutes.SpecializationCourses.name,
        component: () =>
          import(
            '@/modules/admin/components/specialization/SpecializationCourses.vue'
          ),
      },
    ],
  },
];
export default adminCoursesRoutes;
