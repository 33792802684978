import type { RouteRecordRaw } from 'vue-router';
import { adminEnumRoutes } from './admin.enum.routes';

const journeysRoutes: Array<RouteRecordRaw> = [
  {
    path: adminEnumRoutes.journeys.path,
    children: [
      {
        path: '',
        name: adminEnumRoutes.journeys.name,
        meta: adminEnumRoutes.journeys.meta,
        component: () =>
          import(
            '@/modules/admin/pages/journey-planner/JourneyPlannerList.vue'
          ),
      },
      {
        path: adminEnumRoutes.createJourneys.path,
        name: adminEnumRoutes.createJourneys.name,
        meta: adminEnumRoutes.createJourneys.meta,
        component: () =>
          import(
            '@/modules/admin/pages/journey-planner/CreateEditJourneyPlanner.vue'
          ),
      },
      {
        path: adminEnumRoutes.journeysOverview.path,
        children: [
          {
            path: adminEnumRoutes.journeysOverview.path,
            name: adminEnumRoutes.journeysOverview.name,
            meta: adminEnumRoutes.journeysOverview.meta,
            component: () =>
              import('@/modules/admin/components/journey/JourneyLayout.vue'),
            children: [
              {
                path: adminEnumRoutes.journeyDetails.path,
                name: adminEnumRoutes.journeyDetails.name,
                meta: adminEnumRoutes.journeyDetails.meta,
                component: () =>
                  import(
                    '@/modules/admin/pages/journey-planner/ShowJourneyPlanner.vue'
                  ),
              },
              {
                path: adminEnumRoutes.journeyItems.path,
                name: adminEnumRoutes.journeyItems.name,
                meta: adminEnumRoutes.journeyItems.meta,
                component: () =>
                  import(
                    '@/modules/admin/pages/journey-planner/JourneyPlannerItems.vue'
                  ),
              },
              {
                path: adminEnumRoutes.journeyAssign.path,
                name: adminEnumRoutes.journeyAssign.name,
                meta: adminEnumRoutes.journeyAssign.meta,
                component: () =>
                  import(
                    '@/modules/admin/components/journey/JourneyAssign.vue'
                  ),
              },
              {
                path: adminEnumRoutes.journeyItemsOverView.path,
                name: adminEnumRoutes.journeyItemsOverView.name,
                meta: adminEnumRoutes.journeyItemsOverView.meta,
                component: () =>
                  import(
                    '@/modules/admin/pages/journey-planner/ShowJourneyItem.vue'
                  ),
              },
              {
                path: adminEnumRoutes.journeyItemsEdit.path,
                name: adminEnumRoutes.journeyItemsEdit.name,
                meta: adminEnumRoutes.journeyItemsEdit.meta,
                component: () =>
                  import(
                    '@/modules/admin/components/journey/NewJourneyItem.vue'
                  ),
              },
              {
                path: adminEnumRoutes.createJourneysItem.path,
                name: adminEnumRoutes.createJourneysItem.name,
                meta: adminEnumRoutes.createJourneysItem.meta,
                component: () =>
                  import(
                    '@/modules/admin/components/journey/NewJourneyItem.vue'
                  ),
              },
              {
                path: adminEnumRoutes.editJourney.path,
                name: adminEnumRoutes.editJourney.name,
                meta: adminEnumRoutes.editJourney.meta,
                component: () =>
                  import(
                    '@/modules/admin/pages/journey-planner/CreateEditJourneyPlanner.vue'
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
export default journeysRoutes;
