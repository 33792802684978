export const chartsApiHandler = {
  getSalesPerDay: {
    method: "GET",
    path: () => `org-charts/sales`,
    params: {} as {'startDate'?: string | number, 'endDate'?: string | number}
  },
  getRevenuePerCategory: {
    method: "GET",
    path: () => `org-charts/revenue`,
    params: {} as {'startDate'?: string | number, 'endDate'?: string | number}
  },
  getUsersGrowthPerDay: {
    method: "GET",
    path: () => `org-charts/users-growth`,
    params: {} as {'startDate'?: string | number, 'endDate'?: string | number}
  },
  getActiveUsersPerDay: {
    method: "GET",
    path: () => `org-charts/active-users`,
    params: {} as {'startDate'?: string | number, 'endDate'?: string | number}
  },
  getUsersEarnedCertificatesPerDay: {
    method: "GET",
    path: () => `org-charts/certificates`,
    params: {} as {'startDate'?: string | number, 'endDate'?: string | number, 'courseId'?: string | number}
  },
  getEnrollmentsPerDay: {
    method: "GET",
    path: () => `org-charts/enrollments`,
    params: {} as {'startDate'?: string | number, 'endDate'?: string | number, 'courseId'?: string | number}
  },
  getUserRolesDistribution: {
    method: "GET",
    path: () => `org-charts/roles`
  },
  getSalesStats: {
    method: "GET",
    path: () => `org-charts/sales-stats`,
    params: {} as {'firstInterval[startDate]'?: string | number, 'firstInterval[endDate]'?: string | number, 'secondInterval[startDate]'?: string | number, 'secondInterval[endDate]'?: string | number}
  },
  getUsersGrowthStats: {
    method: "GET",
    path: () => `org-charts/user-growth-stats`,
    params: {} as {'firstInterval[startDate]'?: string | number, 'firstInterval[endDate]'?: string | number, 'secondInterval[startDate]'?: string | number, 'secondInterval[endDate]'?: string | number}
  },
  getUsersActivityStats: {
    method: "GET",
    path: () => `org-charts/user-activity-stats`,
    params: {} as {'firstInterval[startDate]'?: string | number, 'firstInterval[endDate]'?: string | number, 'secondInterval[startDate]'?: string | number, 'secondInterval[endDate]'?: string | number}
  },
  getUsersRetentionStats: {
    method: "GET",
    path: () => `org-charts/user-retention-stats`
  },
  getRecentCoursesPurchaseAmount: {
    method: "GET",
    path: () => `org-charts/recent-courses-purchase-amount`
  },
  getTopSellingCourse: {
    method: "GET",
    path: () => `org-charts/top-selling-course`
  }
};