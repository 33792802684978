import { type IRoute } from '@/helpers/interfaces/auth/routes.enum.interface.ts';

export const authEnumRoutes: IRoute = {
  root: {
    path: '/auth',
    name: 'Auth',
    meta: {
      requiresAuth: false,
      requiresLogout: true,
    },
  },
  login: {
    path: '/auth/login',
    name: 'Login',
    meta: {
      title: 'auth.authTabTitle',
      requiresAuth: false,
      requiresLogout: true,
    },
  },
  forgotPassword: {
    path: '/auth/forgot-password',
    name: 'Forgot Password',
    alias: 'forget-password',
    meta: {
      title: 'auth.forgetPasswordTabTitle',
      requiresAuth: false,
      requiresLogout: true,
    },
  },
  forgetPassword: {
    path: '/auth/forget-password',
  },
  resetPassword: {
    path: '/auth/reset-password',
    name: 'Reset Password',
    meta: {
      title: 'auth.resetPasswordTabTitle',
      requiresAuth: false,
      requiresLogout: true,
    },
  },
  twoFactorVerification: {
    path: '/auth/verification-code',
    name: 'Two Factor Verification Page',
    meta: {
      title: 'auth.twoFactorVerificationTabTitle',
      requiresAuth: false,
      requiresLogout: true,
    },
  },
};
